import type { AlignItemsProperty, Responsive } from '@mentimeter/ragnar-styled';
import { Box, type ButtonT } from '@mentimeter/ragnar-ui';
import { P } from 'src/ui/typography';
import { Button } from '.';

export interface ActionT extends ButtonT {
  description?: string | undefined;
  description_second_line?: string;
  alignItems?: Responsive<AlignItemsProperty> | undefined;
  descriptionColor?: string;
  overrideVariant?: {
    backgroundColor?: string;
    color?: string;
    activeBackgroundColor?: string;
    hoverBackgroundColor?: string;
  };
}

export const Action = ({
  alignItems = 'center',
  size = 'large',
  descriptionColor,
  overrideVariant,
  ...props
}: ActionT) => (
  <Box alignItems={alignItems}>
    <Button
      key={props.href}
      size={size}
      extend={
        overrideVariant
          ? () => ({
              color: overrideVariant?.color,
              backgroundColor: overrideVariant.backgroundColor,
              border: 'none',
              ':hover': {
                color: overrideVariant?.color,
                backgroundColor: overrideVariant.hoverBackgroundColor,
              },
              ':active': {
                color: overrideVariant?.color,
                backgroundColor: overrideVariant.activeBackgroundColor,
              },
            })
          : undefined
      }
      {...props}
    />
    {props.description && (
      <P
        fontSize={[1, 2]}
        color={descriptionColor ?? 'textWeaker'}
        mb={0}
        mt={2}
      >
        {props.description}
      </P>
    )}
    {props.description_second_line && (
      <P
        fontSize={[1, 2]}
        color={descriptionColor ?? 'textWeaker'}
        mb={0}
        mt={2}
      >
        {props.description_second_line}
      </P>
    )}
  </Box>
);
