import * as React from 'react';
import type { Responsive } from '@mentimeter/ragnar-styled';
import type { Width } from '@mentimeter/ragnar-dsc';
import type { FrameT } from '../FrameBrowser';
import { Ratio } from '../Ratio';
import { Box, type BoxT } from '../Box';

export interface FramePhoneT extends FrameT, Omit<BoxT, 'children'> {
  children: React.ReactNode;
  width?: Responsive<Width>;
}

export const FramePhone = ({
  children,
  ratio = '216.6667%', //19:5:9 iPhone X,
  width = '340px',
  ...rest
}: FramePhoneT) => {
  return (
    <Box position="relative" width={width} {...rest}>
      <Box
        borderStyle="solid"
        borderColor="borderWeaker"
        bg="primaryWeakest"
        borderWidth="6px"
        borderRadius={35}
        width={width}
        height="100%"
        overflow="hidden"
      >
        <Ratio ratio={ratio} width="100%" bg="bg">
          {children}
        </Ratio>
      </Box>
    </Box>
  );
};
