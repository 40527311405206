import * as React from 'react';
import { Box, type ButtonT } from '@mentimeter/ragnar-ui';
import { useReferralPropMapper } from 'src/utils/useReferralPropMapper';
import type { ActionT } from './Action';
import { Action } from './Action';
interface GroupT {
  actions?: Array<ActionT>;
  size?: ButtonT['size'];
}

export const ActionGroup = ({ actions = [], size }: GroupT) => {
  const referralPropMapper = useReferralPropMapper();
  const mappedActions: ActionT[] = actions.map(referralPropMapper);

  const [primaryAction, ...furtherActions] = mappedActions;
  if (!primaryAction) return null;

  return (
    <Box
      flexDirection={['column', 'row']}
      alignItems={['center', 'flex-start']}
    >
      <Action
        key={primaryAction.href}
        size={size}
        variant="primary"
        {...primaryAction}
      />
      {furtherActions.map((props) => (
        <Action
          key={props.href}
          ml={[0, 2]}
          mt={[2, 0]}
          size={size}
          variant="outline"
          {...props}
        />
      ))}
    </Box>
  );
};
