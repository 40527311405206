import * as React from 'react';
import { Box, type BoxT } from '../Box';

export interface RatioT extends BoxT {
  children?: React.ReactNode;
  ratio: string;
}

export const Ratio = ({ ratio, children, extend, ...props }: RatioT) => {
  return (
    <Box
      width="100%"
      {...props}
      display="block"
      extend={({ ...rest }) => ({
        // Cross browser supported ratio
        ...extend?.(rest),
        '&:before': {
          paddingBottom: ratio,
          display: 'block',
          content: '""',
          height: 0,
          width: 0,
        },
      })}
    >
      <Box position="absolute" top="0" left="0" right="0" bottom="0">
        {children}
      </Box>
    </Box>
  );
};

export const RATIO_SQUARE = '100%'; // 1:1
export const RATIO_LANDSCAPE = '56.25%'; // 16:9
export const RATIO_PORTRAIT = '177.7778%'; // 9:16
