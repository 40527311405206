import * as React from 'react';
import type { VideoT } from '@mentimeter/motion';
import { InView, Video } from '@mentimeter/motion';
import { v4 as uuidv4 } from 'uuid';
import dynamic from 'next/dynamic';

export type MotionT = VideoT;

// Lottie import causes an error when using Cloudflare Pages complaining about
// document not being defined during server rendering.
const Lottie = dynamic(
  () => import('@mentimeter/motion').then((mod) => mod.Lottie),
  { ssr: false },
);

export function Motion(props: MotionT) {
  const uniqueId = uuidv4();
  const isLottie = props.src?.endsWith('.json'); // current implementation is assuming we have .json file extension

  if (isLottie) {
    return (
      <InView threshold={0.5}>
        {({ inView }) => (
          <Lottie key={uniqueId} play={inView} path={props.src} />
        )}
      </InView>
    );
  }

  return (
    <InView threshold={0.5}>
      {({ inView }) => <Video key={uniqueId} play={inView} {...props} />}
    </InView>
  );
}
